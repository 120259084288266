function About() {
  return (
    <>
      <link rel="preload" href="https://imgs.xkcd.com/comics/the_general_problem.png" as="image" />
      <section id="about" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <h2>About Me</h2>
        <p>Hi, my name is Patrick. Welcome to my site.</p>
        <p>I am passionate about technology and work at <a href="https://zerxis.com" target="_blank" rel="noopener noreferrer">zerxis.com</a>.</p>
        <p>Feel free to explore my projects and get in touch!</p>
        <img src="https://imgs.xkcd.com/comics/the_general_problem.png" alt="The General Problem" style={{ maxWidth: '100%', height: 'auto', marginTop: '20px' }} />
      </section>
    </>
  );
}

export default About;
